/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.

window.$ = require('jquery');
window.Popper = require('popper.js');
require('bootstrap');

import moment from 'moment';
moment.locale('fr');

function prev(startDateId, endDateId)
{
    let dateStartSearchValue = document.querySelector('#' + startDateId);
    let dateStartSearch = moment(dateStartSearchValue.value);
    dateStartSearch = dateStartSearch.subtract(1, 'M');
    dateStartSearchValue.value = dateStartSearch.format('YYYY-MM-DD');

    let dateEndSearchValue = document.querySelector('#' + endDateId);
    let dateEndSearch = moment(dateStartSearch);
    dateEndSearchValue.value = dateEndSearch.endOf('month').format('YYYY-MM-DD');

    displayDate(startDateId, endDateId);
}

function next(startDateId, endDateId)
{
    let dateStartSearchValue = document.querySelector('#' + startDateId);
    let dateStartSearch = moment(dateStartSearchValue.value);
    dateStartSearch = dateStartSearch.add(1, 'M');
    dateStartSearchValue.value = dateStartSearch.format('YYYY-MM-DD');

    let dateEndSearchValue = document.querySelector('#' + endDateId);
    let dateEndSearch = moment(dateStartSearch);
    dateEndSearchValue.value = dateEndSearch.endOf('month').format('YYYY-MM-DD');

    displayDate(startDateId, endDateId);
}

function displayDate(startDateId, endDateId)
{
    let dateStartSearchValue = document.querySelector('#' + startDateId);
    let dateStartSearch = moment(dateStartSearchValue.value);

    let dateEndSearchValue = document.querySelector('#' + endDateId);
    let dateEndSearch = moment(dateStartSearch);

    let displayDate = '';

    if (dateStartSearch.format('YYYY-MM') == dateEndSearch.format('YYYY-MM')) {
        displayDate = dateStartSearch.format('MMMM YYYY');
    } else {
        displayDate = dateStartSearch.format('DD MMMM YYYY') + ' - ' + dateEndSearch.format('DD MMMM YYYY');
    }

    document.querySelector('.display-date').textContent = displayDate;
}

document.addEventListener('DOMContentLoaded', function() {  
    let startDateId = 'banking_operation_search_start';
    let endDateId = 'banking_operation_search_end';
    document.querySelector('.prev').addEventListener('click', function(e) {
        prev(startDateId, endDateId);
    });

    document.querySelector('.next').addEventListener('click', function(e) {
        next(startDateId, endDateId);
    });

    displayDate(startDateId, endDateId);
});